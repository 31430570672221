import React from 'react';

interface LabelValuePairProps extends React.PropsWithChildren {
    readonly label: string;
    readonly value: string;
    readonly isLink?: boolean;
    readonly isMailto?: boolean;
    readonly className?: string;
}

const LabelValuePair = (props: LabelValuePairProps): React.JSX.Element => {
    return (
        <div className={'label-value-pair ' + props.className}>
            <div className="mb-0 lh-sm">
                {props.isLink === true && typeof(props.value) === 'string' ? (
                    <a href={props.value} target="_blank" rel="noreferrer" className="text-secondary">
                        {props.value}
                    </a>
                ) : (props.isMailto === true && typeof(props.value) === 'string') ? (
                    <a href={'mailto:' + props.value} className="text-secondary">
                        {props.value}
                    </a>
                ) : (
                    <>
                        {props.value}
                    </>
                )}
            </div>
            <label className="text-black-50 pb-1">{props.label}</label>
        </div>
    );
};

export default LabelValuePair;
