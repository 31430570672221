import Property from '../../Entity/Property/Property';
import {getAccessibilityTypeLabel} from '../../Entity/AccessibilityType';
import {getLastUsageLabel} from '../../Entity/Property/LastUsage';
import SpecialObjectCategory, {getSpecialObjectCategoryLabel} from '../../Entity/Property/SpecialObjectCategory';
import SpecialObjectSeparation, {getSpecialObjectSeparationLabel} from '../../Entity/Property/SpecialObjectSeparation';
import CollapseCard from '../../Component/CollapseCard/CollapseCard';
import LabelValuesPair from '../../../../components/LabelValuesPair';
import LabelValuePair from '../../../../components/LabelValuePair';
import React from 'react';

interface DetailCollapseCardProps {
    readonly property: Property;
}

const DetailCollapseCard = (props: DetailCollapseCardProps): React.JSX.Element => {
    const specialObjectCategoryValues: string[] = props.property.detail.specialObjectCategories.map((specialObjectCategory: SpecialObjectCategory): string => {
        return getSpecialObjectCategoryLabel(specialObjectCategory);
    });

    const specialObjectSeparationValues: string[] = props.property.detail.specialObjectSeparations.map((specialObjectSeparation: SpecialObjectSeparation): string => {
        return getSpecialObjectSeparationLabel(specialObjectSeparation);
    });

    const calculateTotalAreaSize = (): number => {
        let totalAreaSize: number = 0;

        totalAreaSize += props.property.detail.areaSize;

        if (props.property.detail.secondaryAreaSize !== null) {
            totalAreaSize += props.property.detail.secondaryAreaSize;
        }

        return totalAreaSize;
    };

    return (
        <CollapseCard cardType="shadow" title="Objektinformationen" className="mb-3">
            <div className="row d-flex align-items-end">
                {props.property.detail.floors.length > 0 &&
                    <div className="col-12 col-lg-4 mb-3">
                        <LabelValuesPair label="Etage(n)" values={props.property.detail.floors} delimiter=", " />
                    </div>
                }
                {props.property.detail.specialObjectCategories.length > 0 &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuesPair label="Spezialobjekt" values={specialObjectCategoryValues} delimiter=", " />
                    </div>
                }
                {props.property.detail.specialObjectSeparations.length > 0 &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuesPair label="Zeitliche/Räumliche Trennung" values={specialObjectSeparationValues} delimiter=", " />
                    </div>
                }
            </div>
            <div className="row">
                {props.property.detail.areaSize > 0 &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="(Verkaufs-)Fläche"
                            value={new Intl.NumberFormat('de-DE').format(props.property.detail.areaSize) + ' m²'}
                        />
                    </div>
                }
                {(props.property.detail.secondaryAreaSize !== null && props.property.detail.secondaryAreaSize > 0) &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="Nebenfläche"
                            value={new Intl.NumberFormat('de-DE').format(props.property.detail.secondaryAreaSize) + ' m²'}
                        />
                    </div>
                }
                {calculateTotalAreaSize() > 0 &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="Gesamtfläche"
                            value={new Intl.NumberFormat('de-DE').format(calculateTotalAreaSize()) + ' m²'}
                        />
                    </div>
                }
                {(props.property.detail.outdoorSalesAreaSize !== null && props.property.detail.outdoorSalesAreaSize > 0) &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="Außenverkaufsfläche"
                            value={new Intl.NumberFormat('de-DE').format(props.property.detail.outdoorSalesAreaSize) + ' m²'}
                        />
                    </div>
                }
                {(props.property.detail.storeWidth !== null && props.property.detail.storeWidth > 0) &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="Ladenbreite"
                            value={new Intl.NumberFormat('de-DE').format(props.property.detail.storeWidth) + ' m'}
                        />
                    </div>
                }
                {(props.property.detail.shopWindowFrontLength !== null && props.property.detail.shopWindowFrontLength > 0) &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="Schaufensterfront"
                            value={new Intl.NumberFormat('de-DE').format(props.property.detail.shopWindowFrontLength) + ' m'}
                        />
                    </div>
                }
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <LabelValuePair
                        label="Barrierefreier Zugang"
                        value={getAccessibilityTypeLabel(props.property.detail.accessibilityType)}
                    />
                </div>
                {props.property.detail.groundLevelSalesArea === true &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Ebenerdige Verkaufsfläche" value="ist vorhanden" />
                    </div>
                }
                {props.property.detail.parkingLotAvailable === true &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Parkplätze am Objekt" value="sind vorhanden" />
                    </div>
                }
                {props.property.detail.numberOfParkingLots !== null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Anzahl Parkplätze" value={new Intl.NumberFormat('de-DE').format(props.property.detail.numberOfParkingLots)} />
                    </div>
                }
                {props.property.detail.parkingLotsExplanation !== null &&
                    <div className="col-12 col-md-6 col-lg-8 mb-3">
                        <LabelValuePair label="Erläuterung zu den Parkplätzen" value={props.property.detail.parkingLotsExplanation} />
                    </div>
                }
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <LabelValuePair
                        label="Objektbeschreibung/ehemalige Nutzung"
                        value={props.property.detail.objectDescription}
                    />
                </div>
            </div>
            {props.property.detail.lastUsage !== null &&
                <div className="row">
                    <div className="col-12 mb-3">
                        <LabelValuePair
                            label="Letzte Nutzung"
                            value={getLastUsageLabel(props.property.detail.lastUsage)}
                        />
                    </div>
                </div>
            }
        </CollapseCard>
    );
};

export default DetailCollapseCard;
