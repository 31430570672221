import AuthenticationService from './domain/shared/Authentication/AuthenticationService';
import AuthenticationState from './domain/shared/Entity/Authentication/AuthenticationState';
import UserBaseLayout from './domain/user/BaseLayout';
import LeanMatchBaseLayout from './domain/lean_match/BaseLayout';
import AdminBaseLayout from './domain/admin/BaseLayout';
import {useAppSelector} from './app/hooks';
import {setAuthToken} from './api/utilities';
import {fetchUser} from './features/user/userSlice';
import React, {useEffect} from 'react';
import {NavigateFunction, Outlet, useNavigate} from 'react-router-dom';
import {Dispatch} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';

const authenticationService: AuthenticationService = new AuthenticationService(process.env.REACT_APP_LLASM_API_URL!);

const App = (): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const navigate: NavigateFunction = useNavigate();

    const dispatch: Dispatch<any> = useDispatch();

//    // TODO - deprecated
    if (authenticatedUser !== undefined && authenticatedUser !== null ) {
        setAuthToken(authenticatedUser.token);
    }


    useEffect((): void => {
        if (authenticatedUser === undefined || authenticatedUser === null ) {
            return;
        }

        dispatch(fetchUser(authenticatedUser));
    });

    useEffect((): void => {
        if (authenticatedUser === null || authenticatedUser === undefined) {
            navigate('/login');
            return;
        }

        authenticationService.refreshTimeToLife(6);
    });

    if (process.env.REACT_APP_FRONTEND! === 'user') {
        return (
            <UserBaseLayout>
                <Outlet />
            </UserBaseLayout>
        );
    }

    if (process.env.REACT_APP_FRONTEND! === 'lean_match') {
        return (
            <LeanMatchBaseLayout>
                <Outlet />
            </LeanMatchBaseLayout>
        );
    }

    if (process.env.REACT_APP_FRONTEND! === 'admin') {
        return (
            <AdminBaseLayout>
                <Outlet />
            </AdminBaseLayout>
        );
    }

    return (
        <></>
    );
};

export default App;
