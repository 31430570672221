import Contact from '../Entity/Contact/Contact';
import ContactDetailInformation from './ContactDetailInformation';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import Divider from '../Component/Divider/Divider';
import React from 'react';

interface ContactCollapseCardProps {
    readonly contacts: Contact[];
}

const ContactCollapseCard = (props: ContactCollapseCardProps): React.JSX.Element => {
    return (
        <CollapseCard cardType="shadow" title="Ansprechpartner" className="mb-3">
            {props.contacts.map((contact: Contact, index: number): React.JSX.Element => (
                <div key={'contact-' + index}>
                    {index > 0 &&
                        <Divider color="lightgrey" />
                    }
                    <ContactDetailInformation contact={contact} />
                </div>
            ))}
        </CollapseCard>
    );
};

export default ContactCollapseCard;
