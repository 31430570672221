import LocationService from '../Location/LocationService';
import Property from '../Entity/Property/Property';
import LocationDetail from '../Entity/Property/LocationDetail';
import Detail from '../Entity/Property/Detail';
import OfferDetail from '../Entity/Property/OfferDetail/OfferDetail';
import AdditionalDetail from '../Entity/Property/AdditionalDetail';
import EnergyCertificate from '../Entity/Property/EnergyCertificate/EnergyCertificate';
import EnergyCertificateType from '../Entity/Property/EnergyCertificate/EnergyCertificateType';
import FormData from '../Entity/Form/FormData';
import SelectOption from '../Entity/Form/SelectOption';
import MarketingStatus, {getMarketingStatusLabel} from '../Entity/MarketingStatus';
import Place from '../Entity/Location/Place';
import RentalDetail from '../Entity/Property/OfferDetail/RentalDetail';
import PurchaseDetail from '../Entity/Property/OfferDetail/PurchaseDetail';
import InformationScope from '../Entity/InformationScope';
import LocationDetailForm from './LocationDetailForm';
import DetailForm from './DetailForm';
import OfferDetailForm from './OfferDetailForm';
import EnergyCertificateForm from './EnergyCertificateForm';
import AdditionalDetailForm from './AdditionalDetailForm';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import NumericValidationDefinition from '../FormValidationHandler/NumericValidationDefinition';
import MinMaxLengthValidationDefinition from '../FormValidationHandler/MinMaxLengthValidationDefinition';
import NumericRangeOrNullValidationDefinition from '../FormValidationHandler/NumericRangeOrNullValidationDefinition';
import SelectOrCreateContact from '../Contact/SelectOrCreateContact';
import SwitchField from '../Component/Form/Field/SwitchField';
import InputField from '../Component/Form/Field/InputField';
import SelectField from '../Component/Form/Field/SelectField';
import Card from '../Component/Card/Card';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import React, {useEffect, useState} from 'react';

interface PropertyFormProps {
    readonly formData: FormData<Property>;
    readonly setFormData: (formData: FormData<Property>) => void;
    readonly countryPlaces: Place[];
    readonly defaultCountryPlace?: Place;
    readonly formValidationHandler?: FormValidationHandler<Property>;
    readonly setLocationDetailFormData?: (formData: FormData<LocationDetail>) => void;
    readonly setDetailFormData?: (formData: FormData<Detail>) => void;
    readonly setOfferDetailFormData?: (formData: FormData<OfferDetail>) => void;
    readonly setRentalDetailFormData?: (formData: FormData<RentalDetail>) => void;
    readonly setPurchaseDetailFormData?: (formData: FormData<PurchaseDetail>) => void;
    readonly setAdditionalDetailFormData?: (formData: FormData<AdditionalDetail>) => void;
    readonly setEnergyCertificateFormData?: (formData: FormData<EnergyCertificate> | null) => void;
}

const locationService: LocationService = new LocationService(process.env.REACT_APP_LLASM_API_URL!);

const marketingStatusSelectOptions: SelectOption<MarketingStatus>[] = [
    {label: getMarketingStatusLabel(MarketingStatus.Available), value: MarketingStatus.Available},
    {label: getMarketingStatusLabel(MarketingStatus.Reserved), value: MarketingStatus.Reserved},
    {label: getMarketingStatusLabel(MarketingStatus.Allocated), value: MarketingStatus.Allocated},
];

const locationDetailFieldValidationDefinitions: FieldValidationDefinition<LocationDetail>[] = [
    new RequiredValidationDefinition<LocationDetail>('city', 'Ein Ort muss gewählt sein.'),
    new RequiredValidationDefinition<LocationDetail>('postalCode', 'PLZ muss ausgefüllt sein.'),
    new NumericValidationDefinition<LocationDetail>('postalCode', 'PLZ darf nur aus Zahlen bestehen.'),
    new RequiredValidationDefinition<LocationDetail>('locationType', 'Standort-Typ muss ausgefüllt sein.'),
    new RequiredValidationDefinition<LocationDetail>('locationCategory', 'Lagekategorie muss ausgefüllt sein.'),
    new RequiredValidationDefinition<LocationDetail>('locationFactors', 'Standort Faktoren müssen ausgewählt sein.'),
    new MinMaxLengthValidationDefinition<LocationDetail>('locationFactors', 2, 5, 'Standort Faktoren müssen ausgewählt sein.'),
];

const detailFieldValidationDefinitions: FieldValidationDefinition<Detail>[] = [
    new RequiredValidationDefinition<Detail>('floors', 'Etage(n) muss gewählt sein.'),
    new RequiredValidationDefinition<Detail>('areaSize', '(Verkaufs-)Fläche muss ausgefüllt sein.'),
    new RequiredValidationDefinition<Detail>('objectDescription', 'Objektbeschreibung muss ausgefüllt sein.'),
];

const offerDetailFieldValidationDefinitions: FieldValidationDefinition<OfferDetail>[] = [
    new RequiredValidationDefinition<OfferDetail>('propertyAcquisitionTypes', 'Es muss mindestens eine Angebotsart angegeben sein.'),
    new RequiredValidationDefinition<OfferDetail>('fromTimeAvailability', '"Verfügbar ab" muss ausgefüllt sein.'),
];

const rentalDetailFieldValidationDefinitions: FieldValidationDefinition<RentalDetail>[] = [];

const purchaseDetailFieldValidationDefinitions: FieldValidationDefinition<PurchaseDetail>[] = [];

const energyCertificateFieldValidationDefinitions: FieldValidationDefinition<EnergyCertificate>[] = [
    new NumericRangeOrNullValidationDefinition('buildingConstructionYear', 1000, new Date().getFullYear() + 10, 'Es muss ein gültiges Baujahr eingegeben werden.'),
];

const additionalDetailFieldValidationDefinitions: FieldValidationDefinition<AdditionalDetail>[] = [];

const PropertyForm = (props: PropertyFormProps): React.JSX.Element => {
    const property: Property = props.formData.data;

    const [locationDetailFormData, setLocationDetailFormData] = useState<FormData<LocationDetail>>({data: props.formData.data.locationDetail});

    const [detailFormData, setDetailFormData] = useState<FormData<Detail>>({data: props.formData.data.detail});

    const [offerDetailFormData, setOfferDetailFormData] = useState<FormData<OfferDetail>>({data: props.formData.data.offerDetail});

    const [rentalDetailFormData, setRentalDetailFormData] = useState<FormData<RentalDetail>>({data: props.formData.data.offerDetail.rentalDetail});

    const [purchaseDetailFormData, setPurchaseDetailFormData] = useState<FormData<PurchaseDetail>>({data: props.formData.data.offerDetail.purchaseDetail});

    const [additionalDetailFormData, setAdditionalDetailFormData] = useState<FormData<AdditionalDetail>>({data: props.formData.data.additionalDetail});

    const [energyCertificateFormData, setEnergyCertificateFormData] = useState<FormData<EnergyCertificate> | null>(props.formData.data.energyCertificate !== null ? {data: props.formData.data.energyCertificate} : null);

    useEffect((): void => {
        property.locationDetail = locationDetailFormData.data;

        if (props.setLocationDetailFormData !== undefined ) {
            props.setLocationDetailFormData(locationDetailFormData);
        }

        updateFormData();
    }, [locationDetailFormData]);

    useEffect((): void => {
        property.detail = detailFormData.data;

        if (props.setDetailFormData !== undefined) {
            props.setDetailFormData(detailFormData);
        }

        updateFormData();
    }, [detailFormData]);

    useEffect((): void => {
        property.offerDetail = offerDetailFormData.data;

        if (props.setOfferDetailFormData !== undefined) {
            props.setOfferDetailFormData(offerDetailFormData);
        }

        updateFormData();
    }, [offerDetailFormData]);

    useEffect((): void => {
        property.offerDetail.rentalDetail = rentalDetailFormData.data;

        if (props.setRentalDetailFormData !== undefined) {
            props.setRentalDetailFormData(rentalDetailFormData);
        }

        updateFormData();
    }, [rentalDetailFormData]);

    useEffect((): void => {
        property.offerDetail.purchaseDetail = purchaseDetailFormData.data;

        if (props.setPurchaseDetailFormData !== undefined) {
            props.setPurchaseDetailFormData(purchaseDetailFormData);
        }

        updateFormData();
    }, [purchaseDetailFormData]);

    useEffect((): void => {
        property.additionalDetail = additionalDetailFormData.data;

        if (props.setAdditionalDetailFormData !== undefined) {
            props.setAdditionalDetailFormData(additionalDetailFormData);
        }

        updateFormData();
    }, [additionalDetailFormData]);

    useEffect((): void => {
        if (energyCertificateFormData === null) {
            if (props.setEnergyCertificateFormData !== undefined ) {
                props.setEnergyCertificateFormData(null);
            }

            return;
        }

        property.energyCertificate = energyCertificateFormData.data;

        if (props.setEnergyCertificateFormData !== undefined ) {
            props.setEnergyCertificateFormData(energyCertificateFormData);
        }

        updateFormData();
    }, [energyCertificateFormData]);

    const fetchMarketingStatusSelectedOption = (): SelectOption<MarketingStatus> | undefined => {
        if (property.marketingStatus === undefined) {
            return undefined;
        }

        return marketingStatusSelectOptions.find((selectOption: SelectOption<MarketingStatus>): boolean => {
            return property.marketingStatus === selectOption.value;
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
        switch (event.target.name) {
            case 'internalDesignation':
                if (event.target.value === '') {
                    property.internalDesignation = null;
                } else {
                    property.internalDesignation = event.target.value;
                }

                break;
            default:
                (property as any)[event.target.name] = event.target.value;

                break;
        }

        updateFormData();
        validateField(event.target.name);
    };

    const handleMarketingStatusChange = (selectedValue: SelectOption<MarketingStatus> | null): void => {
        if (selectedValue === null) {
            return;
        }

        property.marketingStatus = selectedValue.value;

        updateFormData();
        validateField('marketingStatus');
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        (property as any)[event.target.name] = event.target.checked;

        updateFormData();
    };

    const handleHasEnergyCertificateChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked === true) {
            if (property.energyCertificate === null) {
                property.energyCertificate = new EnergyCertificate();

                property.energyCertificate.energyCertificateType = EnergyCertificateType.EnergyRequirementCertificate;
                property.energyCertificate.withHotWater = false;
            }

            setEnergyCertificateFormData({data: property.energyCertificate});
        } else {
            setEnergyCertificateFormData(null);
        }
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: property});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <>
            <Card cardType="shadow" className="mb-3 bg-light">
                <div className="p-3 p-lg-4 p-xl-5">
                    <h2 className="fs-5 fw-bold mb-3">Status</h2>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <SwitchField
                                label={property.active === false ? 'Die Fläche ist nach dem Speichern inaktiv' : 'Die Fläche ist nach dem Speichern aktiv'}
                                description="Hier kannst Du Dein Flächenangebot jederzeit aktiv bzw. inaktiv stellen. Nur für aktive Flächenangebote kann ein Matching gestartet werden."
                                selected={property.active}
                                name="active"
                                onChange={handleSwitchChange}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <SelectField
                                name="marketingStatus"
                                label="Vermarktungsstatus"
                                required={true}
                                options={marketingStatusSelectOptions}
                                value={fetchMarketingStatusSelectedOption()}
                                placeholder="Bitte wählen"
                                onChange={handleMarketingStatusChange}
                                description="Hier gibst Du den aktuellen Vermarktungsstatus für Dein Objekt an. Suchende Unternehmen, denen Du Dein Objekt angeboten hast, sehen diesen Vermarktungsstatus als Feedback in ihrem Bereich."
                                formErrors={FormValidationHandler.getFieldErrors(props.formData, 'marketingStatus')}
                            />
                        </div>
                    </div>
                </div>
            </Card>
            <CollapseCard cardType="shadow" title="Bezeichnung und Lage" className="mb-3 pb-3">
                <div className="row mb-3">
                    <div className="col-md-6">
                        <InputField
                            name="title"
                            label="Bezeichnung der Fläche"
                            description="Trage hier eine Bezeichnung/einen Titel für die Fläche ein, um diese von Deinen anderen Flächenangeboten zu unterscheiden. Diese Bezeichnung erscheint auch im Matching."
                            type="text"
                            required={true}
                            value={property.title}
                            onChange={handleChange}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'title')}
                        />
                    </div>
                    <div className="col-md-6">
                        <InputField
                            name="internalDesignation"
                            label="Interne Bezeichnung"
                            description="Hier hast Du die Möglichkeit, eine interne Bezeichnung für Deine Fläche einzugeben. Dies kann z. B. eine eigene Referenznummer sein. Die Angaben sind nur für Dich sichtbar."
                            type="text"
                            required={false}
                            value={property.internalDesignation ?? undefined}
                            onChange={handleChange}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'internalDesignation')}
                        />
                    </div>
                </div>
                <LocationDetailForm
                    locationService={locationService}
                    formData={locationDetailFormData}
                    setFormData={setLocationDetailFormData}
                    validationDefinitions={locationDetailFieldValidationDefinitions}
                    countryPlaces={props.countryPlaces}
                    defaultCountryPlace={props.defaultCountryPlace}
                />
            </CollapseCard>
            <CollapseCard cardType="shadow" title="Objektinformationen" className="mb-3">
                <DetailForm
                    formData={detailFormData}
                    setFormData={setDetailFormData}
                    validationDefinitions={detailFieldValidationDefinitions}
                />
            </CollapseCard>
            <CollapseCard cardType="shadow" title="Angebotsdaten" className="mb-3">
                <OfferDetailForm
                    formData={offerDetailFormData}
                    setFormData={setOfferDetailFormData}
                    validationDefinitions={offerDetailFieldValidationDefinitions}
                    rentalDetailFormData={rentalDetailFormData}
                    setRentalDetailFormData={setRentalDetailFormData}
                    rentalDetailValidationDefinitions={rentalDetailFieldValidationDefinitions}
                    purchaseDetailFormData={purchaseDetailFormData}
                    setPurchaseDetailFormData={setPurchaseDetailFormData}
                    purchaseDetailValidationDefinitions={purchaseDetailFieldValidationDefinitions}
                    specialObjectCategories={property.detail.specialObjectCategories}
                />
            </CollapseCard>
            <CollapseCard cardType="shadow" title="Energieausweis" className="mb-3">
                <div className="mb-3">
                    <SwitchField
                        label="Energieausweis liegt vor"
                        description="Sofern ein Energieausweis für Dein Objekt vorliegt, aktiviere bitte den Schalter und gib anschließend die entsprechenden Angaben aus dem Energieausweis an."
                        selected={energyCertificateFormData !== null}
                        name="hasEnergyCertificate"
                        onChange={handleHasEnergyCertificateChange}
                />
                </div>
                {energyCertificateFormData !== null &&
                    <EnergyCertificateForm
                        formData={energyCertificateFormData}
                        setFormData={setEnergyCertificateFormData}
                        validationDefinitions={energyCertificateFieldValidationDefinitions}
                    />
                }
            </CollapseCard>
            <CollapseCard cardType="shadow" title="Zusätzliche Informationen" className="mb-3">
                <AdditionalDetailForm
                    formData={additionalDetailFormData}
                    setFormData={setAdditionalDetailFormData}
                    validationDefinitions={additionalDetailFieldValidationDefinitions}
                />
            </CollapseCard>
            <CollapseCard cardType="shadow" title="Ansprechpartner auswählen" className="mb-3">
                <SelectOrCreateContact
                    formData={props.formData}
                    setFormData={props.setFormData}
                    validateField={validateField}
                />
            </CollapseCard>
        </>
    );
};

export default PropertyForm;
